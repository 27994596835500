.App {
    background-color: rgb(24, 24, 27);
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.chat-container {
    height: 100vh;
    min-width: 350px;
    max-width: 350px;
    color: white;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 13.8125px;
    font-family: "Inter", "Roobert", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    padding-right: 7px;
}

.player-container {
    height: 100vh;
    overflow: hidden;
    flex-grow: 100;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.player-container iframe {
    width: 100%;
    height: 100%;
}

.source-code-link {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 1.2em;
    color: white;
    text-decoration: none;
    border-style: solid;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

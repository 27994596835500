.video {
    height: 99.2vh;
    width: 100%;
    border: none;
}

.url-input-form {
    border-radius: 25px;
    width: 50vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: white;
}

.url-input-form input {
    margin-left: 20px;
    font-size: 1em;
    border-radius: 15px;
    margin-bottom: 20px;
}

.submit-button {
    cursor: pointer;
}

.commenter {
    font-weight: 700;
}

.chatMessage {
    padding-left: 10px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.emoticon {
    vertical-align: middle;
    position: relative;
    bottom: 2px;
}

.modifier-wide {
    width: 112px;
    height: 28px;
    object-fit: fill;
}

.modifier-vertical {
    transform: scaleY(-1);
}

.modifier-horizontal {
    transform: scaleX(-1);
}

.modifier-zero-space {
    margin-left: -4px;
}

.badge {
    vertical-align: middle;
    position: relative;
    bottom: 2px;
}

.resetButton {
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 1.5em;
    border-radius: 50%;
    border-style: solid;
    opacity: 0.3;
    padding: 2px;
    transform: scaleY(0.60);
    z-index: 4;
}

.resetButton:hover {
    opacity: 1;
    color: red;
    cursor: pointer;
}

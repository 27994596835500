.seen-upload-chat-file-button {
    font-size: 1em;
}

.hidden-upload-chat-file-button {
    display: none;
}

.chat-search-box {
    margin-top: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    font-size: 1em;
    text-align: center;
    width: 50%;
}

.search-form {
    background-color: gray;
    padding-bottom: 10px;
    padding-top: 10px;
    position: fixed;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-form p {
    line-height: 0;
}

.chat-selector {
    display: flex;
    flex-direction: column;
}

.chat-selection-button {
    background-color: white;
    font-size: 0.9em;
    cursor: pointer;
}

.chat-selection-button:nth-child(1) {
    margin-top: 120px;
}

.chat-selection-button:hover {
    background-color: lightgrey;
}

.chat-selection-button:active {
    background-color: white;
}

.chat-selection-button-title {
    font-weight: 800;
}

.chat-selection-button {
    min-height: 80px;
    margin-top: 15px;
    border-radius: 5px;
}

.chat-selection-button p {
    margin-top: 3px;
    margin-bottom: 3px;
}
